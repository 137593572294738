@import '~foundation-sites/scss/foundation';

// ------- Foundation Overides ---------//

$global-font-size: 18px;
$rem-base: $global-font-size;

$contrast-warnings: false;

$primary-color: #343a72;
$secondary-color: #128383;
$success-color: #3adb76;
$warning-color: #ffae00;
$alert-color: #d0021b;
$pale-grey-color: #cfcdcd;
$light-grey-color: #f0ecf7;
$mid-grey-color: #505050;
$dark-grey-color: #373737;
$darker-grey-color: #1a1a1a;
// ------------- Colors ----------------//

$twig-pink-color: #ff126d;
$twig-blue-color: #005fe6;
$indigo-color: #3442c3;
$footer-color: #0e489b;

$explain-green-color: #028779;
$explore-blue-color: #025274;
$engage-pink-color: #eb6b82;
$elaborate-purple-color: #841f5f;
$evaluate-red-color: #c52441;

$ngss-blue-color: #364296;
$ngss-orange-color: #e9700d;
$ngss-green-color: #7ca035;

$grade-k-color: #7270b4;
$grade-tk-color: #7270b4;
$grade-1-color: #e57424;
$grade-2-color: #73a145;
$grade-3-color: #228cc2;
$grade-4-color: #15a299;
$grade-5-color: #d43027;
$grade-6-color: #a2238b;

$blue-panel-color: #e2f7fa;
$white: #ffffff;
$lilac-bg-color: #f6f3fa;

// ------------- NGSS Dimensions Colors ----------------//

$pe-pink-color: #e6005b;
$ccc-green-color: #7ca035;
$dci-orange-color: #e9700d;
$sep-blue-color: #364296;

// ------------- Fonts -------------------//

$primary-font: 'Bariol', Calibri, sans-serif;
$secondary-font: 'Muli', Calibri, sans-serif;

// ------------- Headings ----------------//

$header-font-family: $primary-font;
$menu-font-family: $primary-font;
$header-color: $primary-color;
$header-margin-bottom: rem-calc(13px);
$header-pill-margin: 4rem;
$header-font-weight: 700;

$large-height-header: rem-calc(50pt);
$medium-height-header: rem-calc(37pt);
$small-height-header: rem-calc(30pt);

$section-header: 1.76rem;

// ------------- Body ----------------//

$body-font-color: $dark-grey-color;
$body-font-family: $secondary-font;
$paragraph-lineheight: 1.5;

// ------------- Anchors ----------------//

$anchor-font-family: $secondary-font;
$anchor-color: #0D7777;
$anchor-text-decoration: underline;
$anchor-text-decoration-hover: underline;
$anchor-color-hover: #094949;

// ------------- Buttons ----------------//

$button-font-family: $secondary-font;
$button-radius: rem-calc(22.5px);
$button-padding: rem-calc(15px 26px);
$small-button-radius: rem-calc(17.5px);
$small-button-padding: rem-calc(10px 18px);

// ------------- Button - Primary ----------------//

$button-background: $secondary-color;
$button-background-hover: scale-color($secondary-color, $lightness: 20%);
$button-color: #fff;
$primary-button-hover: #88c1c1;

// ------------- Button - Secondary ----------------//

$button-background-alt: #fff;
$button-color-alt: $secondary-color;
$button-background-hover-alt: scale-color($secondary-color, $lightness: -80%);
$button-hollow-border-width: rem-calc(1px);
$button-hollow-border-color: $secondary-color;

// ------------- Shadows ----------------//

$shadow-color: #000;
$light-shadow-color: scale-color($shadow-color, $lightness: 80%);
$box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
$card-shadow: 0px 0px 3px 3px #cecece;
$accordion-box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);
$dropdown-box-shadow: rem-calc(-1px 1px 10px 3px) $light-shadow-color;
$container-box-shadow: rem-calc(-1px 1px 10px 3px) $light-shadow-color;
$button-shadow: -0.15rem 0.02rem 0.4rem 0.1rem $light-shadow-color;
$flyout-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);

// ------------- Forms ----------------//

$form-text-font-size: 1rem;
$input-shadow: 0;
$input-radius: 10px;
$form-spacing: 0;
$input-placeholder-color: #757575;

// ------------- Tables ----------------//

$table-background: $white;
$table-stripe: none;
$table-padding: rem-calc(15 10);

// ------------- Borders ----------------//

$card-border: 1px solid #cecece;
$content-divider: 1px solid $pale-grey-color;

$breakpoint-classes: (small medium large xlarge xxlarge);

@import '../../../scss/mixins/accordion';
@import '../../../scss/mixins/buttons';
@import '../../../scss/mixins/forms';
@import '../../../scss/mixins/layout';
@import '../../../scss/mixins/components';
@import '../../../scss/mixins/tabs';
@import '../../../scss/mixins/text';

.layout-route {
    &__content {
        &-main-swooosh {
            @include grid-row(null, null, expand, true);
            @include swooosh-panel();
            padding-bottom: 250px;
        }
    }
    &__footer-swooosh {
        position: relative;
        margin-top: -150px;
        width: 100%;
    }
}
