@charset 'UTF-8';

@font-face {
    font-family: 'Bariol';
    src: url('../font/bariol_regular-webfont.woff2') format('woff2'),
        url('../font/bariol_regular-webfont.woff') format('woff'), url('../font/bariol_regular-webfont.eot'),
        url('../font/bariol_regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../font/bariol_regular-webfont.ttf') format('truetype'),
        url('../font/bariol_regular-webfont.svg#bariolregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Bariol';
    src: url('../font/bariol_bold-webfont.woff2') format('woff2'),
        url('../font/bariol_bold-webfont.woff') format('woff'), url('../font/bariol_bold-webfont.eot'),
        url('../font/bariol_bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../font/bariol_bold-webfont.ttf') format('truetype'),
        url('../font/bariol_bold-webfont.svg#bariolbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

// Remove after primiryBoldFont is totally removed
@font-face {
    font-family: 'Bariol-Bold';
    src: url('../font/bariol_bold-webfont.woff2') format('woff2'),
        url('../font/bariol_bold-webfont.woff') format('woff'), url('../font/bariol_bold-webfont.eot'),
        url('../font/bariol_bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../font/bariol_bold-webfont.ttf') format('truetype'),
        url('../font/bariol_bold-webfont.svg#bariolbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('../font/muli-webfont.woff2') format('woff2'), url('../font/muli-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
