@font-face {
	font-family: "Icons";
	font-display: block;
	src: url("https://cdn-static.twigscience.com/tsc-react/Icons.5356b4ee0848f03a72c0.eot?#iefix") format("embedded-opentype"),
url("https://cdn-static.twigscience.com/tsc-react/Icons.5356b4ee0848f03a72c0.woff") format("woff"),
url("https://cdn-static.twigscience.com/tsc-react/Icons.5356b4ee0848f03a72c0.woff2") format("woff2"),
url("https://cdn-static.twigscience.com/tsc-react/Icons.5356b4ee0848f03a72c0.ttf") format("truetype"),
url("https://cdn-static.twigscience.com/tsc-react/Icons.5356b4ee0848f03a72c0.svg#Icons") format("svg");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: Icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-account:before {
	content: "\f101";
}
.icon-activitySheet:before {
	content: "\f102";
}
.icon-assessment:before {
	content: "\f103";
}
.icon-assign:before {
	content: "\f104";
}
.icon-astronaut:before {
	content: "\f105";
}
.icon-audio:before {
	content: "\f106";
}
.icon-bold:before {
	content: "\f107";
}
.icon-bulleted-list:before {
	content: "\f108";
}
.icon-check:before {
	content: "\f109";
}
.icon-chevron-down-thin:before {
	content: "\f10a";
}
.icon-chevron-down:before {
	content: "\f10b";
}
.icon-chevron-left-thin:before {
	content: "\f10c";
}
.icon-chevron-left:before {
	content: "\f10d";
}
.icon-chevron-right:before {
	content: "\f10e";
}
.icon-chevron-tall:before {
	content: "\f10f";
}
.icon-chevron-up:before {
	content: "\f110";
}
.icon-close:before {
	content: "\f111";
}
.icon-color-picker:before {
	content: "\f112";
}
.icon-cross:before {
	content: "\f113";
}
.icon-delete:before {
	content: "\f114";
}
.icon-deteriorating:before {
	content: "\f115";
}
.icon-digital-student:before {
	content: "\f116";
}
.icon-discussion:before {
	content: "\f117";
}
.icon-document:before {
	content: "\f118";
}
.icon-dot-background-left:before {
	content: "\f119";
}
.icon-dot-background-right:before {
	content: "\f11a";
}
.icon-download:before {
	content: "\f11b";
}
.icon-draw:before {
	content: "\f11c";
}
.icon-e-book:before {
	content: "\f11d";
}
.icon-edit:before {
	content: "\f11e";
}
.icon-false:before {
	content: "\f11f";
}
.icon-film:before {
	content: "\f120";
}
.icon-full-screen:before {
	content: "\f121";
}
.icon-gallery:before {
	content: "\f122";
}
.icon-game:before {
	content: "\f123";
}
.icon-globe:before {
	content: "\f124";
}
.icon-google-classroom:before {
	content: "\f125";
}
.icon-hands-on-lesson:before {
	content: "\f126";
}
.icon-helmet:before {
	content: "\f127";
}
.icon-help:before {
	content: "\f128";
}
.icon-highlight:before {
	content: "\f129";
}
.icon-improving:before {
	content: "\f12a";
}
.icon-Info:before {
	content: "\f12b";
}
.icon-interactive:before {
	content: "\f12c";
}
.icon-italics:before {
	content: "\f12d";
}
.icon-lesson:before {
	content: "\f12e";
}
.icon-link:before {
	content: "\f12f";
}
.icon-locked:before {
	content: "\f130";
}
.icon-logout:before {
	content: "\f131";
}
.icon-menu:before {
	content: "\f132";
}
.icon-minimise:before {
	content: "\f133";
}
.icon-new-tab:before {
	content: "\f134";
}
.icon-not-found:before {
	content: "\f135";
}
.icon-numbered-list:before {
	content: "\f136";
}
.icon-pdf:before {
	content: "\f137";
}
.icon-pin:before {
	content: "\f138";
}
.icon-play:before {
	content: "\f139";
}
.icon-poster:before {
	content: "\f13a";
}
.icon-present:before {
	content: "\f13b";
}
.icon-presentation:before {
	content: "\f13c";
}
.icon-print:before {
	content: "\f13d";
}
.icon-quiz:before {
	content: "\f13e";
}
.icon-reading-for-evidence:before {
	content: "\f13f";
}
.icon-results:before {
	content: "\f140";
}
.icon-rocket-landing:before {
	content: "\f141";
}
.icon-rocket-launching:before {
	content: "\f142";
}
.icon-rocket:before {
	content: "\f143";
}
.icon-science-letter-of-the-day:before {
	content: "\f144";
}
.icon-scores:before {
	content: "\f145";
}
.icon-search:before {
	content: "\f146";
}
.icon-share:before {
	content: "\f147";
}
.icon-strikethrough:before {
	content: "\f148";
}
.icon-student:before {
	content: "\f149";
}
.icon-studentlesson:before {
	content: "\f14a";
}
.icon-teach-mode:before {
	content: "\f14b";
}
.icon-teach:before {
	content: "\f14c";
}
.icon-tick-simple:before {
	content: "\f14d";
}
.icon-tick:before {
	content: "\f14e";
}
.icon-troubleshooterBackground:before {
	content: "\f14f";
}
.icon-true:before {
	content: "\f150";
}
.icon-twig-book:before {
	content: "\f151";
}
.icon-underline:before {
	content: "\f152";
}
.icon-visual:before {
	content: "\f153";
}
.icon-volcano-color:before {
	content: "\f154";
}
.icon-volcano:before {
	content: "\f155";
}
.icon-warning:before {
	content: "\f156";
}
.icon-write:before {
	content: "\f157";
}
