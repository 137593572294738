@mixin tab($color, $text-align, $font-size: 18px) {
    cursor: pointer;
    @include breakpoint(large) {
        font-size: $font-size;
        padding: 0 2rem 1rem;
    }
    font-family: $primary-font;
    font-weight: 400;
    line-height: 24px;
    text-align: $text-align;
    color: $color;
    border-bottom: 10px solid transparent;
    padding: 0 1rem 1rem;
    list-style: none;
    display: inline-block;
    &[aria-selected='true'] {
        border-bottom: 10px solid $primary-color;
        font-family: $primary-font;
        font-weight: 700;
    }
}
